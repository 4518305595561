import React, { useState } from 'react';
import moment from 'moment';
import './chat.scss';

const TextBubble = ({ message, out = false, time }) => {
    const backgroundColor = out ? '#493AEB' : '#E6E9FF';
    const hourColor = out ? '#E6E9FF' : '#493AEB';
    const textColor = out ? '#FFFFFF' : '#707070';
    const hours = moment(time).format('MMM Do YYYY, h:mm a');
    return (
        <div
            style={{
                flexDirection: out ? 'row-reverse' : 'row',
                display: 'flex',
                paddingTop: 15,
                flexShrink: 0,
                paddingRight: 12,
            }}>
            <div
                style={{
                    padding: 16,
                    backgroundColor: backgroundColor,
                    maxWidth: '80%',
                    borderRadius: 8,
                    color: textColor,
                    fontSize: 15, // Safari requirement
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <span>{message}</span>
                <span
                    style={{
                        fontSize: '10px',
                        color: hourColor,
                        marginTop: 10,
                    }}>
                    {hours}
                </span>
            </div>
        </div>
    );
};

const ChatEvents = ({ data, myId }) => {
    if (!data || !Array.isArray(data)) {
        return null;
    }

    return data
        .map((event, idx) => {
            const out = event.from === myId;
            return (
                <TextBubble
                    key={`chatEvent${idx}`}
                    out={out}
                    message={event.message}
                    time={event.moment}
                />
            );
        })
        .reverse();
};

const Chat = ({ onBack, onSend, chatRef, data, myId }) => {
    const [message, setMessage] = useState('');
    const sendMessage = () => {
        if (message !== '') {
            onSend(message);
            setMessage('');
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };
    return (
        <div className="Chat">
            <div className="bg-gradient-blue Chat-header">
                <div className="App-header Chat-title ">
                    <div onClick={onBack} style={{ paddingLeft: 26 }}>
                        <span
                            className="Icon Icon-back-arrow"
                            style={{ height: 30 }}></span>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontWeight: '700',
                        }}>
                        CHAT
                    </div>
                    <div style={{ width: 50 }}> </div>
                </div>
                <div className="Chat-box">
                    <div className="Case-box-close-button" onClick={onBack}>
                        <span
                            className={'Icon Icon-close'}
                            style={{
                                height: 'inherit',
                                width: 25,
                                filter:
                                    'invert(52%) sepia(63%) saturate(683%) hue-rotate(168deg) brightness(106%) contrast(101%)',
                            }}></span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column-reverse',
                            overflow: 'auto',
                        }}>
                        <div
                            className="messages"
                            ref={chatRef}
                            style={{
                                display: 'flex',
                                maxFlex: 1,
                                height: 0,
                                flexDirection: 'column-reverse',
                            }}>
                            <ChatEvents data={data} myId={myId} />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingTop: 10,
                            alignItems: 'center',

                            borderTopStyle: 'solid',
                            borderTopColor: '#C7C7C7',
                            borderTopWidth: 1,

                            marginTop: 10,
                        }}>
                        <input
                            style={{
                                height: 45,
                                flex: 1,
                                marginRight: 20,
                                fontSize: 15,
                                border: 0,
                            }}
                            onKeyDown={handleKeyDown}
                            value={message}
                            placeholder={'New message'}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <div
                            onClick={() => {
                                sendMessage();
                            }}
                            style={{
                                color: '#707070',
                                fontSize: 15,
                                fontWeight: '600',
                            }}>
                            {/* <img src={Send} alt="send icon" /> */}
                            { message === '' || message?.length <= 0 ? null :
                                (<span key={'send'} className="Icon Icon-send" />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Chat };
