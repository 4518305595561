// import { lineString } from '@turf/helpers';
// import length from '@turf/length';

/**
 * Returns a string representing the date based on the provided zuluDateTime.
 *
 * If the zuluDateTime is the same as the current date, the function returns 'Today'.
 * If the zuluDateTime is the next day, the function returns 'Tomorrow'.
 * Otherwise, the function returns a string representing the date in the format 'Wed Dec 08 2021'.
 *
 * @param {string} zuluDateTime - The zulu datetime string to be converted to a date string.
 * @returns {string} - A string representing the date based on the provided zuluDateTime.
 */
const dateFromDateString = (zuluDateTime) => {
    const when = new Date(zuluDateTime).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    const tomorrow = new Date().setHours(24, 0, 0, 0);

    if (today === when) {
        return 'Today';
    } else if (tomorrow === when) {
        return 'Tomorrow';
    }

    return new Date(when).toDateString();
};

const hourMinutesFromDateString = (zuluDateTime) => {
    const theMoment = new Date(zuluDateTime);
    return formatAMPM(theMoment);
    /*
    const time = `${theMoment.getHours()}:${(
        '' + theMoment.getMinutes()
    ).padStart(2, '0')}`;
    return time;
    */
};

/**
 * Calculate start of delivery interval
 * @param zuluDateTime {Date} is the date object
 * @param start_moment {Date} is whether the delivery route has started or not
 * @param start_hour {Number} is the start hour of the shift
 * @param endTime {boolean} is whether the function is processing start or end delivery time
 * @return {string}
 */
const minimumHourMinutesFromDateString = (zuluDateTime, start_moment, start_hour= 15, endTime = false) => {
    const theMoment = prepareMinimumTimeOfDeparture(zuluDateTime, start_moment, start_hour, endTime);
    return formatAMPM(theMoment);
    /*
    const time = `${theMoment.getHours()}:${(
        '' + theMoment.getMinutes()
    ).padStart(2, '0')}`;
    return time;
    */
};

const formatAMPM = (date) => {
    let h = date.getHours();
    let minutes = date.getMinutes();
    let ampm = h >= 12 ? 'PM' : 'AM';
    h = h % 12;
    h = h ? h : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return h + ':' + minutes + ' ' + ampm;
};

/**
 * Prepare delivery time intervall to show
 * @param zuluDateTime {Date} is the date object
 * @param start_moment {Date} is whether the delivery route has started or not
 * @param start_hour {Number} is the start hour of the shift
 * @param endTime {boolean} is whether the function is processing start or end delivery time
 * @return {Date}
 */
const prepareMinimumTimeOfDeparture = (zuluDateTime, start_moment = undefined, start_hour = 15, endTime = false) => {
    const theMoment = new Date(zuluDateTime);
    if (!start_moment && !endTime) {
        if (theMoment.getDay() > 0 && theMoment.getDay() < 6) {
            theMoment.setHours(start_hour, 0);
            /*
            if (theMoment.getHours() < 14 && theMoment.getHours() > 2) {
                theMoment.setHours(15, 0);
            }
            */
        } else {
            theMoment.setHours(start_hour, 0);
            /*
            if (theMoment.getHours() < 14 && theMoment.getHours() > 2) {
                theMoment.setHours(14, 0);
            }
            */
        }
    }
    return theMoment;
};

const getEstimation = (zuluDateTime) => {
    return prepareMinimumTimeOfDeparture(zuluDateTime);
};

export {
    dateFromDateString,
    hourMinutesFromDateString,
    getEstimation,
    minimumHourMinutesFromDateString,
    prepareMinimumTimeOfDeparture,
};
